.slidea {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  transform-style: preserve-3d;
}

.slidea:after {
  content: "";
  display: table;
  clear: both;
}

.slidea.slidea-dragging {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: move;
}

.slidea .slide {
  visibility: 'hidden';
}

.slidea .slidea-outer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.slidea .slidea-outer:after {
  content: "";
  display: table;
  clear: both;
}

.slidea .slidea-inner {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.slidea .slidea-inner .slidea-slide {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
}

.slidea .slidea-inner .slidea-slide.active {
  display: block;
  z-index: 2;
}

.slidea .slidea-inner .slidea-slide.previous {
  display: block;
  z-index: 1;
}

.slidea .slidea-inner .slidea-slide .slidea-layer-wrapper {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0;
}

.slidea .slidea-inner .slidea-slide .slidea-layer-wrapper .slidea-layer {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-inner .slidea-slide .slidea-object.object-absolute {
  position: absolute;
  width: 100%;
}

.slidea .slidea-inner .slidea-slide .slidea-content-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: 999;
}

.slidea .slidea-inner .slidea-slide .slidea-content {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.slidea .slidea-inner .slidea-slide .slidea-content.content-center > .content-container {
  display: table-cell;
  vertical-align: middle;
}

.slidea .slidea-inner .slidea-slide .slidea-content.content-top > .content-container {
  display: table-cell;
  vertical-align: top;
}

.slidea .slidea-inner .slidea-slide .slidea-content.content-bottom > .content-container {
  display: table-cell;
  vertical-align: bottom;
}

.slidea .slidea-inner .slidea-slide .slidea-canvas {
  display: block;
  position: absolute;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-inner .slidea-slide .slidea-background-wrapper {
  display: block;
  position: absolute;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-inner .slidea-slide .slidea-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.slidea .slidea-inner .slidea-slide .slidea-video-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.slidea .slidea-inner .slidea-slide .slidea-video {
  width: 100%;
  height: auto;
}

.slidea .slidea-inner .slidea-slide .slidea-video-cover {
  display: block;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.slidea .slidea-inner .slidea-slide iframe {
  border: none;
}

.slidea .slidea-inner .slidea-slide .slidea-grid {
  transform: translate3d(0, 0, 0);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-inner .slidea-slide .slidea-grid .slidea-grid-cell {
  display: block;
  float: left;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  overflow: hidden;
}

.slidea .slidea-grid-slide {
  opacity: 1 !important;
}

.slidea .slidea-grid-slide .slidea-background-wrapper .slidea-background-main {
  opacity: 0;
}

.slidea .slidea-loader-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.slidea .slidea-loader-wrapper .slidea-loader-text {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-title {
  margin-bottom: 0;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-subtitle {
  margin-bottom: 0;
}

.slidea .slidea-loader-wrapper .slidea-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  float: left;
  width: 20%;
}

.slidea .slidea-progress {
  display: block;
  position: absolute;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10px;
  z-index: 3;
}

.slidea .slidea-progress.slidea-progress-top {
  top: 0;
}

.slidea .slidea-progress.slidea-progress-bottom {
  bottom: 0;
}

.slidea .slidea-progress .slidea-progress-bar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 10px;
}

.slidea .slidea-control {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 3;
}

.slidea .slidea-control.slidea-next {
  right: 0;
}

.slidea .slidea-control.slidea-next .slidea-control-text {
  float: right;
}

.slidea .slidea-control.slidea-next .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
}

.slidea .slidea-control.slidea-next:hover .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slidea .slidea-control.slidea-prev {
  left: 0;
}

.slidea .slidea-control.slidea-prev .slidea-control-text {
  float: left;
}

.slidea .slidea-control.slidea-prev .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slidea .slidea-control.slidea-prev:hover .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

.slidea .slidea-control .slidea-control-inner {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-text {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  position: relative;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
  width: 40px;
  height: 80px;
  z-index: 5;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-text:hover {
  text-decoration: none;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-thumbnail {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  position: absolute;
  height: 100%;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-thumbnail .slidea-control-image {
  display: block;
  width: auto;
  height: 100%;
}

.slidea-pagination {
  display: block;
  text-align: center;
  width: 100%;
  height: auto;
  z-index: 3;
}

.slidea-pagination.slidea-pagination-inside {
  position: absolute;
  bottom: 50px;
  left: 0;
  height: 20px;
}

.slidea-pagination.slidea-pagination-outside {
  display: block;
  position: relative;
  margin: 25px auto;
}

.slidea-pagination .slidea-pagination-bullet {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin: 10px;
  width: 10px;
  height: 10px;
  z-index: 999;
}

.slidea-pagination .slidea-pagination-bullet.active {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  background: white;
}

.slidea-thumbnails {
  width: 100%;
  height: auto;
  position: relative;
  display: block;
  margin: 0 0 25px;
  overflow: hidden;
}

.slidea-thumbnails-inner {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper {
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper .slidea-thumbnail {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
}
